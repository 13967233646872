import React, { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import Config from "./config";

export const ForAdoption = (props) => {

  const [forAdoption, setForAdoption] = useState([]);

  const cfg = new Config();

  useEffect(async () => {
    const forAdoptionResponse = await cfg.loadForAdoption();
    setForAdoption(forAdoptionResponse.data.filter(x => !x.expired));
  }, []);

  const convertToImageGalleryFormat = (photos) => {
    return photos.map(photo => {
      const photoUrl = cfg.buildUrl("for-adoption", photo);
      return {
        original: photoUrl,
        thumbnail: photoUrl
      };
    });
  }

  return (
    <div id="for-adoption" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Do adopcji</h2>
        </div>
        <div className="row display-flex">
          <div className="col-xs-12 col-md-12">
            {forAdoption.length === 0 &&
              <h5>Aktualnie nie posiadamy zwierząt do adopcji</h5>
            }
            {forAdoption.length > 0 &&
              forAdoption.map((d, i) => (
                <div key={`${d.name}-${i}`}>
                  <h5>{d.name}</h5>
                  <div className="description-container">
                    <div className="description" dangerouslySetInnerHTML={{ __html: d.description }}></div>
                  </div>
                  <ImageGallery items={convertToImageGalleryFormat(d.photos)} />
                </div>
              ))
            }
            <a
              href="https://forms.gle/RiEt6seR4r6gesg68"
              target="_blank"
              className="btn btn-custom2 btn-sm">
              Wypełnij ankietę adopcyjną online
            </a>
            <div className="margin-top-15">
              <p>Lub pobierz i wypełnij ręcznie:</p>
              <a href={require("../../src/assets/AnkietaKundelDomek.pdf")} download="AnkietaKundelDomek">Pobierz ankietę</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
