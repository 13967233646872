import {BrowserView, MobileView} from 'react-device-detect';
export const DonateFromYourTax = (props) => {
    return (
    <div>
          <div id="donate-tax" className="text-center">
            <div className="container">
                <div className="section-title">
                <h2>Podaruj nam 1,5% podatku</h2>
                </div>
                <div className="col-md-10 col-md-offset-1">
                    <img src={require("../../src/assets/tax-1.png")} style={{width: "100%"}} alt="" />{" "}
                    <img src={require("../../src/assets/tax-2.png")} style={{width: "100%"}} alt="" />{" "}
                </div>
            </div>
          </div>
      </div>
      );
}