import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { ForAdoption } from "./components/for-adoption";
import { About } from "./components/about";
import { HelpUs } from "./components/help-us";
import { Gallery } from "./components/gallery";
import { Charities } from "./components/charities";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import { Toaster } from 'react-hot-toast';
import "./App.css";
import { DonateFromYourTax } from "./components/donateFromYourTax";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [state, setState] = useState({});

  useEffect(() => {
    setState(
      JsonData
    );
  }, []);

  return (
    <div>
      <Navigation />
      <Toaster position="bottom-center" toastOptions={{ duration: 2000 }} />
      <Header data={state.Header} />
      <DonateFromYourTax />
      <ForAdoption data={state.ForAdoption} />
      <About data={state.About} />
      <HelpUs data={state.HelpUs} />
      <Gallery />
      <Charities />
      <Team data={state.Team} />
      <Contact data={state.Contact} />
    </div>
  );
};

export default App;
